"use strict";

/**
 * Define Global Variables
 */

/**
 * Document Ready - Scripts are called after page load basic element
 */
$(document).ready(function () {
  /**
   * Overwrite Function From Nette - Forms
   * @param form
   * @param errors
   */
  Nette.showFormErrors = function (form, errors) {
    messageError("fa-exclamation-circle", errors[0].message, "error");
  };

  google.maps.event.addDomListener(window, 'load', initialize);
  openTestimonial();
  openNews();
  $(".cezetmap .kraj a").click(function () {
    var $this = $(this);
    var thisLink = $this.attr("href");
    window.location.href = thisLink;
  });
});
/**
 * Window Resize - Scripts are called after window is resized
 */

$(window).resize(function () {});
/* ---------------------------------------- Function Declaration ---------------------------------------- */

/**
 * Google Map on Contact Page
 */

function initialize() {
  var map_element = "#map";

  if ($(map_element).length) {
    var centerMap = new google.maps.LatLng(50.0697707, 14.4889472);
    var markerOne = new google.maps.LatLng(50.0697707, 14.4889472);
    var mapProp = {
      center: centerMap,
      zoom: 18,
      disableDefaultUI: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    var map = new google.maps.Map(document.getElementById("map"), mapProp);
    var markerMapOne = new google.maps.Marker({
      position: markerOne,
      labelContent: "Magistrů 168/2, 140 00 Praha",
      labelAnchor: new google.maps.Point(22, 0)
    });
    markerMapOne.setMap(map);
  }
}
/**
 * Open Testimonal - Open / Close
 */


function openNews() {
  $("#news .moreInformation").click(function () {
    var messageShow = $(this).parents(".news-detail").find(".message").css("display");
    $(this).parents(".news-detail").find(".message").toggle();

    if (messageShow == "block") {
      $(this).html("Zobrazit více informací");
    } else {
      $(this).html("Skrýt dodatečné informace");
    }
  });
}
/**
 * Open Testimonal - Open / Close
 */


function openTestimonial() {
  $("#testimonials .moreInformation").click(function () {
    var messageShow = $(this).parent().find(".message").css("display");
    $(this).parent().find(".message").toggle();

    if (messageShow == "block") {
      $(this).html("Více...");
    } else {
      $(this).html("Skrýt...");
    }
  });
}